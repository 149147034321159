import React, { useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm';
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { subirSustentoSolicitudCuponAPI } from '../../../../consumers/backendApisUrls';

const SubirSustento = ({ toggleModal, refreshPage, IdSolicitudCuponTiempoLibre }) => {
  const KEYS = { sustento: "SolicitudCuponSustentoFileViewModel" };
  const SECTION_NAME = "SubirSustento";
  const [formValues, setFormValues] = useState({
    [KEYS.sustento]: {},
    IdSolicitudCuponTiempoLibre
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await submitForm(Object.values(KEYS), subirSustentoSolicitudCuponAPI(), formValues, SECTION_NAME, successReasignarVacacionesCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const successReasignarVacacionesCallback = async () => {
    toggleModal();
    await refreshPage();
  };

  return (
    <ModalForm
      showModal={toggleModal}
      titulo="Subir sustento"
      width="600px"
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}>
      <InputUploadFile
        label="Sustento"
        name={KEYS.sustento}
        uploadFile={formValues[KEYS.sustento]}
        setUploadFile={(res) => setFormValues({ ...formValues, [KEYS.sustento]: res })}
        sectionName={SECTION_NAME}
        setIsUploading={setIsSubmitting}
        validExtensions={["pdf", "jpg", "png"]}
      />
    </ModalForm>
  );
}

export default SubirSustento;