import React, { useContext, useState } from 'react';
import { AuthContext } from "../../hooks/context/authContext";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { deleteFileFromS3API, getAreasVacacionesByDepartamentoAPI, getDepartamentosVacacionesAPI, getDataEmpleadoAPI, getGerenciasByDivisionAPI , getAreasByGerenciaAPI , downloadReporteSeguimientoVacacionesBackOfficeAPI } from "../../consumers/backendApisUrls";
import { downloadFile } from "../../consumers/backendConsumer";
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from "../../helper/utils";
import SelectInput from '../../components/Select/SelectInput';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { useEffect } from 'react';
import { clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput } from '../../validations/FormValidatorUtils';
import MultiSelectInput from '../../components/MultiSelectInput/MultiSelectInput';
import { ROLES } from '../../constants/hardCodedConstants';

const ReporteVacacionesDetallado = () => {
  const seccionName = "ReporteVacacionesBackOffice";
  const { state } = useContext(AuthContext);
  const [executePostRequest] = usePostRequest();
  const [loading, setLoading] = useState(false);
  const IdEmpleado = state.user.id_empleado;

  const [departamentoOptions, setDepartamentoOptions] = useState([]);
  const [jsonSelectedDepartamentoValue, setJsonSelectedDepartamentoValue] = useState(undefined);
  const [flatInactivoDepartamento, setFlatInactivoDepartamento] = useState(false);


  const [areasOptions, setAreasOptions] = useState([]);
  const [jsonSelectedAreaValue, setJsonSelectedAreaValue] = useState(undefined);
  const [flatInactivoAreas, setFlatInactivoAreas] = useState(false);

  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, [] );


  const getDivisionEmpleado = async () => {
    const response = await executeGetRequest(getDataEmpleadoAPI(IdEmpleado))
    if (responseCode200(response)) {
      return response?.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }


  const getDivisiones = async function (idDepartamento) {
    const response = await executeGetRequest(getGerenciasByDivisionAPI(idDepartamento));
    if (responseCode200(response)) {
      return response?.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const getSelectedSedes = async (idDivision) => {
    let areaByRegion = await getDivisiones(idDivision);
    if (areaByRegion) {
      let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(areaByRegion);
      if (options && options.length > 0) {

        if(state.roleIds.some(id => id == ROLES.ReporteBOGerenteDivision.id)){
          setAreasOptions(options);

          if (options.length === 1 ) {

            setJsonSelectedAreaValue([options[0]["value"]])
            setFlatInactivoAreas(true);
  
          } else {
            const arreglo = [];
            options.map(r=>{
              arreglo.push(r.value)
            })
            setJsonSelectedAreaValue(arreglo)
            setFlatInactivoAreas(false);
          }
        }
      
      
      }
    }
  }

  const getInitialData = async () => {
    setLoading(true);
    try {
      let division = await getDivisionEmpleado()
   
      if(division){
        let options = await executeGetRequest(getGerenciasByDivisionAPI(division.IdUnidad))
        setFlatInactivoDepartamento(true);
        setDepartamentoOptions(options.data);
        const from = {
          idEntity :   undefined,
          label    :  division.Unidad,
          value    :  options.data[0].Value
        }
        setJsonSelectedDepartamentoValue(from)

        if(state.roleIds.some(id => id == ROLES.ReporteBOGerenteArea.id )){
          const from2 = [{
            value    :  division.IdDepartamento,
            label    :  division.Departamento,
            idEntity :   undefined
          }]
          console.log(from2)
          setAreasOptions(from2)

          if (from2.length === 1 ) {

            setJsonSelectedAreaValue([from2[0]["value"]])
            setFlatInactivoAreas(true);
  
          } else {
            setFlatInactivoAreas(false);
          }

        }
      
       await getSelectedSedes(options.data[0]["IdParent"]);
       }

    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const descargarReporte = async () => {
    let payload = {
      "IdsDepartamentos": jsonSelectedAreaValue,
      // "IdsAreas": 246
    };
console.log("este es el payload" + payload)
    let allInputKeys = ["IdsDepartamentos"];
    let responseReporteGenerado = await executePostRequest(downloadReporteSeguimientoVacacionesBackOfficeAPI(), payload);
    if (responseCode200(responseReporteGenerado)) {
      clearAllInputValidationMessages(allInputKeys, seccionName);
      let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
      if (responseCode200(descargarReporteResponse)) {
        await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
      } else {
        mostrarMensajeDeErrorConToast(descargarReporteResponse);
      }
    } else if (responseTieneModelState(responseReporteGenerado)) {
      mostrarMensajesDeValidacionEnCadaInput(responseReporteGenerado, allInputKeys, seccionName);
    } else {
      clearAllInputValidationMessages(allInputKeys, seccionName);
      mostrarMensajeDeErrorConToast(responseReporteGenerado);
    }
  };

  const eliminarArchivoDeS3 = async function (filePath) {
    let deleteFileFromS3Payload = {
      "Path": filePath
    };
    let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
    if (responseCode200(deleteFileFromS3Response)) {
      console.debug("delete file from s3 successfully");
    } else {
      mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
    }
  };

  return (
    <>
      <div className='card-body'>
        <div id='seccionReportesDeAsignacionFamiliar'>
          <div>
            <div className="form-group row form-input-modal-contactanos reportes-label-container">
              <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">División<span className="required">*</span></label>
              <div className="col-sm-4">
                <SelectInput
                  options={departamentoOptions}
                  jsonSelectedValue={jsonSelectedDepartamentoValue}
                  setJsonSelectedValue={setJsonSelectedDepartamentoValue}
                  isDisabled={flatInactivoDepartamento}
                  preFunction={async (selected) => {
                    const isEmpty = Object.keys(selected).length === 0;
                    if (!isEmpty)
                      await getSelectedSedes(selected.value);
                  }}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdsDepartamentos-validation-message`}></p>
              </div>
            </div>
            <div className="form-group row form-input-modal-contactanos reportes-label-container">
              <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Gerencia</label>
              <div className="col-sm-4">
                <MultiSelectInput
                  options={areasOptions}
                  selectedValues={jsonSelectedAreaValue}
                  isDisabled={flatInactivoAreas}
                  setSelectedValues={setJsonSelectedAreaValue}
                  placeholder={"Selecciona una o más opciones"}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdsAreas-validation-message`}></p>
              </div>
            </div>
          </div>

          <div className="reporte-datos-generales-dato-obligatorio">
            <p>(<span className="required ">*</span>) Dato obligatorio</p>
            <p><strong>Si no tiene seleccionado ninguna Sede, buscara todas las sedes de la región</strong></p>
          </div>

          <div>
            <button className="btn btn-primary generic-button-ficha reportes-descargar-button" onClick={descargarReporte}>{loading ? 'Descargando' : 'Descargar'} Reporte {loading ? '...' : ''}</button>
          </div>
        </div>
      </div>
    </>
  )
};

export default ReporteVacacionesDetallado;