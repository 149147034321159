import React, { useEffect, useState } from "react";
import {
  aprobarSolicitudCambioSueldo,
  exportarSolicitudesCambioSueldoAprobadas,
  getAllEstadosAPI,
  getAllSedesAPI,
  getBancosValidosAPI,
  listarSolicitudesCambioCuentaSueldo,
  rechazarSolicitudCambioSueldo,
} from "../../consumers/backendApisUrls";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import SearchContainer from "../../components/pageComponents/SearchContainer";
import ModalEnabled from "../../components/modalComponents/ModalEnabled";
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from "../../helper/utils";
import { useIndexValues } from "../../hooks/useIndexValues/useIndexValues";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import PageWrapper from "../../components/pageComponents/PageWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import TableSelectSearch from "../../components/atoms/TableSelectSearch";
import PageTable from "../../components/pageComponents/PageTable";
import SpinnerLoading from "../../components/pageComponents/SpinnerLoading";
import TableWebBody from "./TableWebBody";
import PageButtons from "../../components/pageComponents/PageButtons";
import { useGenerarDescargarEliminarArchivo } from "../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import { useSubmitForm } from "../../hooks/useSubmitForm/useSubmitForm";

export const AdminSolicitudCambioCuentaSueldo = () => {
  const KEYS_FILTERS = {
    Sedes: "IdsSedes",
    Estados: "IdsEstados",
    Bancos: "IdsBancos"
  };

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const [allFilters, setAllFilters] = useState();
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();
  const [searchIndex] = useIndexValues(setFormValues, setPaginator, setExtraData);
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();
  const [submitForm] = useSubmitForm();

  const initialPromises = () => {
    return [
      executeGet(getAllSedesAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getBancosValidosAPI())
    ]
  }

  const init = async () => {
    setLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(KEYS_FILTERS), setAllFilters)
    } catch (error) {
      console.error(error);
    } finally {
      manageSearch();
      setLoading(false);
    }
  };

  const manageSearch = async () => {
    const api = listarSolicitudesCambioCuentaSueldo();
    let payload = getPayload();
    setIsSearching(true);
    await executePost(api, payload, searchIndex, false);
    setIsSearching(false);
  };

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      ...selectedFilters
    }
  }

  const handleFilterSelected = (res, key) => {
    setSelectedFilters(lastData => ({...lastData, [key]: res }));
  }
  
  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage])

  useDidMountEffect(() => {
    if(jsonNoEstaVacio(selectedFilters)) manageSearch();
  }, [selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allFilters, extraData, setFilters);
  }, [extraData])

  useEffect(() => {
    init();
  }, []);

  const aprobarSolicitud = async (id) => {
    const api = aprobarSolicitudCambioSueldo();
    let payload = {
      IdSolicitudCambioCuentaSueldo: id
    };
    setIsSearching(true);
    await executePost(api, payload, async () => await manageSearch());
    setIsSearching(false);
  }

  const rechazarSolicitud = async (KEYS, form, SECTION_NAME) => {
    setIsSearching(true);
    try {
      await submitForm(
        Object.values(KEYS),
        rechazarSolicitudCambioSueldo(),
        form,
        SECTION_NAME,
        successCallback
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsSearching(false);
    }
  };

  const successCallback = async () => {
    setModalEnabled({isEnable: false});
    manageSearch();
  };

  const handleExportarFile = async () => {
    await downloadAndDelete(exportarSolicitudesCambioSueldoAprobadas(), {});
  };
  
  const tableHeaders = [
    {label: "Fecha de Registro"},
    {label: "Colaborador"},
    {label: 
      <div className='d-flex justify-content-center'>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Sedes)}
          description={<label className='text-wrap mb-0'>Sede</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Sedes)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Sedes] ?? []}
        />
      </div>
    },
    {label: 
      <div className='d-flex justify-content-center'>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Estados)}
          description={<label className='text-wrap mb-0'>Estado</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Estados)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Estados] ?? []}
        />
      </div>
    },
    {label: 
      <div className='d-flex justify-content-center'>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Bancos)}
          description={<label className='text-wrap mb-0'>Nuevo Banco Sueldo</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Bancos)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Bancos] ?? []}
        />
      </div>
    },
    {label: "Nueva Cuenta Sueldo"},
    {label: "Acciones", col: "col-1"},
  ];

  return (
    <PageWrapper isLoading={loading}>
      <ModalEnabled
        modalEnabled={modalEnabled}
        setModalEnabled={setModalEnabled}
        refreshPage={manageSearch}
      />
      <PageHeader title="Gestión de Solicitudes de Cambio de Cuenta Sueldo" />
      <SearchContainer
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        setPaginator={setPaginator}
        manageSearch={manageSearch}
        addCleanSearch={() => setSelectedFilters({})}>
        <PageButtons hasCreateButton={false}>
          <button
            type="button"
            className="generic-button-buscador"
            onClick={handleExportarFile}>
            Exportar
          </button>
        </PageButtons>
      </SearchContainer>
      {isSearching ? (
        <div className="w-100">
          <SpinnerLoading />
        </div>
      ) : (
        <PageTable
          headers={tableHeaders}
          paginator={paginator}
          setPaginator={setPaginator}>
          <TableWebBody
            filas={formValues}
            setModalEnabled={setModalEnabled}
            manageSearch={manageSearch}
            aprobarSolicitud={aprobarSolicitud}
            rechazarSolicitud={rechazarSolicitud}
          />
        </PageTable>
      )}
    </PageWrapper>
  );
};
