import React, { useEffect, useState } from 'react';
import PageHeader from "../../components/pageHeader/PageHeader";
import { SECCIONES_REPORTES } from "../../constants/hardCodedConstants";
import { getReportesALosQueTieneAccesoAPI } from "../../consumers/backendApisUrls";
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import ReporteDeDatosGenerales from "./ReporteDeDatosGenerales";
import ReporteDeEncuestaDeClimaLaboral from "./ReporteDeEncuestaDeClimaLaboral";
import ReporteDeRenuncias from "./ReporteDeRenuncias";
import ReporteCoordinarDevolucion from "./ReporteCoordinarDevolucion";
import ReporteCierreDeAnio from "./ReporteCierreDeAnio";
import ReporteDeOnomasticos from './ReporteDeOnomasticos';
import ReporteDeNuevosIngresos from './ReporteDeNuevosIngresos';
import ReporteDeVacaciones from './ReporteDeVacaciones';
import ReporteAsignacionFamiliar from './ReporteAsignacionFamiliar';
import ReporteCierreAnioRenovacion from './ReporteCierreAnioRenovacion';
import ReporteAsignacionFamiliarDetallado from "./ReporteAsignacionFamiliarDetallado";
import ReporteVacacionesDetallado from "./ReporteVacacionesDetallado";
import ReporteColaboradoresActivos from "./ReporteColaboradoresActivos";
import ReporteColaboradoresCesados from "./ReporteColaboradoresCesados";
import ReporteVacacionesPlanilla from './ReporteVacacionesPlanilla';
import ReporteSeguimientoNuevosIngresos from './ReporteSeguimientoNuevosIngresos';
import ReporteYTablasGoldenbelt from './ReporteYTablasGoldenbelt'; 
import ReporteDatosHijos from './ReporteDatosHijos';
import ReporteBBDDColaboradoresActivos  from './ReporteBBDDColaboradoresActivos';
import ReporteRankmi from './ReporteRankmi';
import ReporteTxtOfisis from './ReporteTxtOfisis';
import ReporteVacacionesMatricial from './ReporteVacacionesMatriciales'
import ReporteTxtEmpleadosONP from './ReporteTxtEmpleadosONP';
import ReporteNuevosIngresosPasosCompletados from './ReporteNuevosIngresosPasosCompletados';
import ReporteDeVacacionesBackOffice from './ReporteDeVacacionesBackOffice';


const Reportes = () => {

    const [reportes, setReportes] = useState([]);

    const [executeGetRequest] = useManageGetRequest();

    const TodosLosReportes = {
        [SECCIONES_REPORTES.ReporteDeDatosGenerales.id]: <ReporteDeDatosGenerales />,
        [SECCIONES_REPORTES.ReporteDeClimaLaboral.id]: <ReporteDeEncuestaDeClimaLaboral/>,
        [SECCIONES_REPORTES.ReporteDeRenuncias.id]: <ReporteDeRenuncias />,
        [SECCIONES_REPORTES.ReporteCoordinarDevolucion.id]: <ReporteCoordinarDevolucion />,
        [SECCIONES_REPORTES.ReporteProcesoDeCierre.id]: <ReporteCierreDeAnio />,
        [SECCIONES_REPORTES.ReporteDeCumpleanios.id]: <ReporteDeOnomasticos />,
        [SECCIONES_REPORTES.ReporteNuevosIngresos.id]: <ReporteDeNuevosIngresos />,
        [SECCIONES_REPORTES.ReporteVacaciones.id]: <ReporteDeVacaciones />,
        [SECCIONES_REPORTES.ReporteAsignacionFamiliarRegistro.id]: <ReporteAsignacionFamiliar />,
        [SECCIONES_REPORTES.ReporteAsignacionFamiliarDetallado.id]: <ReporteAsignacionFamiliarDetallado />,
        [SECCIONES_REPORTES.ReporteCierreAnioRenovacion.id]: <ReporteCierreAnioRenovacion />,
        [SECCIONES_REPORTES.ReporteVacacionesDetallado.id]: <ReporteVacacionesDetallado />,
        [SECCIONES_REPORTES.ReporteVacacionesBackOffice.id]: <ReporteDeVacacionesBackOffice />,

        [SECCIONES_REPORTES.ReporteColaboradoresActivos.id]: <ReporteColaboradoresActivos />,
        [SECCIONES_REPORTES.ReporteColaboradoresCesados.id]: <ReporteColaboradoresCesados />,
        [SECCIONES_REPORTES.ReporteVacacionesPorPeriodoPlanilla.id]: <ReporteVacacionesPlanilla />,
        [SECCIONES_REPORTES.ReporteSeguimientoNuevosIngresos.id]: <ReporteSeguimientoNuevosIngresos />,
        [SECCIONES_REPORTES.ReporteyTablasGoldenbelt.id]: <ReporteYTablasGoldenbelt />,
        [SECCIONES_REPORTES.ReporteRankmiColaboradoresActivos.id]: <ReporteRankmi />,
        [SECCIONES_REPORTES.ReporteDatosHijos.id]: <ReporteDatosHijos />,
        [SECCIONES_REPORTES.ReporteDatosEmpleadoTxtOfisis.id]: <ReporteTxtOfisis />,
        [SECCIONES_REPORTES.ReporteBBDDColaboradoresActivos.id]: <ReporteBBDDColaboradoresActivos />,
        [SECCIONES_REPORTES.ReporteVacacionesMatriciales.id]: <ReporteVacacionesMatricial />,
        [SECCIONES_REPORTES.ReporteTxtEmpleadosONP.id]: <ReporteTxtEmpleadosONP />,
        [SECCIONES_REPORTES.ReporteNuevosIngresosPasosCompletados.id]: <ReporteNuevosIngresosPasosCompletados />,
    }

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        let api = getReportesALosQueTieneAccesoAPI();
        await executeGetRequest(api, (res) => setReportes(res.data));
    };

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Mis Datos no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                <div>
                    <PageHeader title={"Reportes"} />
                    <div className='container-fluid body-content' style={{ 'borderTop': '0.5px solid #eae5e5' }}>
                        <div className='tab-content bg-white' id='myTabContent'>
                            <div
                                className='tab-pane fade show active'
                                id='divDatosPersonales'
                                role='tabpanel'
                                aria-labelledby='home-tab'>
                                <div id='accordion'>
                                    {reportes?.map((reporte, index) => {
                                        return (
                                            <div key={index} className='card'>
                                                <div className='card-header' id={`heading${index}`}>
                                                    <h5 className='mb-0'>
                                                        <button
                                                            className={`btn btn-link font-gothan-medium ficha-titulo-seccion-color`}
                                                            data-toggle='collapse'
                                                            data-target={`#Reporte${reporte?.IdReporte}`}
                                                            aria-expanded='false'
                                                            aria-controls={`Reporte${reporte?.IdReporte}`}>
                                                            {reporte?.NombreReporte}
                                                        </button>
                                                    </h5>
                                                </div>
                                                <div
                                                    id={`Reporte${reporte?.IdReporte}`}
                                                    className={`collapse ${index === 0 && 'show'}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-parent='#accordion'>
                                                    {TodosLosReportes[reporte.IdReporte]}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Reportes;