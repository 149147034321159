import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";
import { RechazarSolicitud } from "./modals/RechazarSolicitud";

const TableWebBody = ({ filas, setModalEnabled, aprobarSolicitud, rechazarSolicitud }) => {
  return (
    <tbody className="listar-tbody">
      {filas.map((fila, i) => (
        <tr key={`${i}-${fila.IdSolicitudCambioCuentaSueldo}`}>
          <td className="">{fila?.FechaCrea}</td>
          <td className="">{fila?.Empleado}</td>
          <td className="">{fila?.Sede}</td>
          <td className="">{fila?.Estado}</td>
          <td className="">{fila?.NuevoBanco}</td>
          <td className="">{fila?.NuevoNroCuentaSueldo}</td>
          <td className="text-center">
            <div
              className="d-flex justify-content-center blue_innova_text"
              style={{ gap: "10px" }}>
              {fila?.PuedeAprobar && (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  className="hand-pointer blue_innova_text"
                  title="Aprobar"
                  onClick={() => {
                    aprobarSolicitud(fila.IdSolicitudCambioCuentaSueldo);
                  }}
                />
              )}
              {fila?.PuedeRechazar && (
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  className="hand-pointer"
                  title="Rechazar"
                  onClick={() => {
                    setModalEnabled({
                      isEnable: true,
                      component: RechazarSolicitud,
                      data: {
                        idSolicitudCambioCuentaSueldo:
                          fila.IdSolicitudCambioCuentaSueldo,
                        empleado: fila.Empleado,
                        rechazarSolicitud: rechazarSolicitud,
                      },
                    });
                  }}
                />
              )}
              {fila?.MotivoRechazo && (
                <GenericTooltip
                  id={'' + fila.IdSolicitudCambioCuentaSueldo}
                  text={fila.MotivoRechazo}
                />
              )}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export default TableWebBody;

const GenericTooltip = ({ id, text }) => (
  <>
    <FontAwesomeIcon
      data-tip data-for={id}
      size='lg'
      icon={faInfoCircle}
    />
    <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style">
      Motivo de rechazo: {text}
    </ReactTooltip>
  </>
);