import React from "react";
import { FILE_FORMATS } from "../../../../constants/hardCodedConstants";
import ModalForm from "../../../../components/modalComponents/ModalForm";
import InputUploadFile from "../../../../components/modalComponents/InputUploadFile";
import { useManagePostRequest } from "../../../../hooks/useManagePostRequest/useManagePostRequest";

const AgregarFirma = ({
  toggleModal,
  refreshPage,
  KEYS_INIT,
  firmaColaborador,
  handleUploadSignature,
  SECTION_NAME,
  onSubmit
}) => {
  const [executePost] = useManagePostRequest();

  async function submitSignature(e) {
    e.preventDefault();
    await executePost(onSubmit, {}, () => refreshPage());
    toggleModal();
  }

  return (
    <ModalForm
      titulo="Agregar firma"
      width="600px"
      showModal={toggleModal}
      hasSaveButton={true}
      onSubmit={submitSignature}>
      <InputUploadFile
        label="Firma para documentos"
        direction="row"
        name={KEYS_INIT.FirmaFileViewModel}
        uploadFile={firmaColaborador[KEYS_INIT.FirmaFileViewModel]}
        setUploadFile={handleUploadSignature}
        sectionName={SECTION_NAME}
        showToastSuccess={false}
        validExtensions={FILE_FORMATS.ALLOWED_IMAGE_FORMATS}
      />
    </ModalForm>
  );
};

export default AgregarFirma;
