import React from 'react';
import PropTypes from 'prop-types';
import tagImage from '../../image/tagNuevo2.png';
import tagImageWhite from '../../image/tagNuevo3.png'

const NewItemIcon = ({color, size, onClick, white = false}) => {
    return (
        <div>
            <img src={white? tagImageWhite : tagImage} width={'70px'} height={'70px'} onClick={onClick}/>
        </div>
    );
};

NewItemIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

export default NewItemIcon;
