import React, { useState, useEffect } from 'react'
import ModalForm from '../../../../../components/modalComponents/ModalForm'
import InputText from '../../../../../components/modalComponents/InputText'
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile'
import { useForm } from '../../../../../hooks/useForm/UseForm'
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm'
import { jsonEstaVacio } from '../../../../../helper/utils'
import { editarPlantillaCuponCuponeraTiempoLibreAPI } from '../../../../../consumers/backendApisUrls'
import InputMultiCheckboxAndRadioCard from '../../../components/InputMultiCheckboxAndRadioCard'

const EXCLUDED_STRINGS = ["Cargos", "EsCuponBloqueado", "Horario", "TipoDeCondicion", "SustentoPlantillaCupon"];

const EditarCupon = ({ toggleModal, refreshPage, row }) => {

  const SECTION_NAME = "EditarNuevoCupon";
  const VALID_TEXTENSION_IMAGE = ["jpg", "png"];
  const KEYS = { IdPlantillaCuponTiempoLibre: "IdPlantillaCuponTiempoLibre", Nombre: "Nombre", Requerimientos: "Requerimientos", ImagenCupon: "ImagenCuponTiempoLibreFileViewModel" };

  const Requerimientos = row.Requerimientos?.split(",") || [];
  const CantidadDiasYFecha = Requerimientos.filter(o => !EXCLUDED_STRINGS.includes(o))?.at(0) || "";
  const [formValues, setFormValues] = useState({...row, Requerimientos, CantidadDiasYFecha});
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = editarPlantillaCuponCuponeraTiempoLibreAPI();
    const payload = getPayload();

    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { CantidadDiasYFecha, ...formValuesRaw } = formValues;
    const payload = {};
    for (const key in KEYS){
      if (formValuesRaw.hasOwnProperty(KEYS[key])){
        payload[KEYS[key]]= formValuesRaw[KEYS[key]];
      }
    }

    if (jsonEstaVacio(payload[KEYS.ImagenCupon]) || (payload[KEYS.ImagenCupon]?.Path === row[KEYS.ImagenCupon]?.Path)){
      delete payload[KEYS.ImagenCupon];
    }

    let requerimientosArray = formValuesRaw[KEYS.Requerimientos].filter(o => EXCLUDED_STRINGS.includes(o));
    if (CantidadDiasYFecha){
      requerimientosArray.push(CantidadDiasYFecha);
    }
    
    return {
      ...payload,
      [KEYS.Requerimientos]: requerimientosArray.length
        ? requerimientosArray.join(',') 
        : "",
    }
  }

  const successSubmit = async () => {
    toggleModal();
    await refreshPage();
  }

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} width="700px" titulo="Editar Cupón" saveButtonDisabled={isUploading} isSubmitting={isSubmitting}>
      <InputText label='Nombre' name={KEYS.Nombre} value={formValues[KEYS.Nombre]} onChange={handleChange} sectionName={SECTION_NAME} />
      <InputUploadFile label="Imagen" name={KEYS.ImagenCupon} uploadFile={formValues[KEYS.ImagenCupon]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.ImagenCupon]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={VALID_TEXTENSION_IMAGE}/>
      <InputMultiCheckboxAndRadioCard label="Requerimientos" name={KEYS.Requerimientos} radioValue={formValues.CantidadDiasYFecha} values={formValues[KEYS.Requerimientos]} onChange={handleChange} sectionName={SECTION_NAME}/>
    </ModalForm>
  )
}

export default EditarCupon;