import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';

const TagsInput = ({itemInfo = [], validarInput, anadirEmails}) => {

    const [state, setState] = useState({tags: itemInfo.Emails});

    useEffect(() => {
        anadirEmails(state.tags, itemInfo.Code);
    }, [state]);

    const removeTag = async (i) => {
        const newTags = [...state.tags];
        newTags.splice(i, 1);
        setState({tags: newTags});
    }

    const inputKeyDown = async (e) => {
        let val = e.target.value;
        if ((e.key === 'Enter' || e.key === ';' || e.key === ',' || e.key === ' ') && val) {
            if (e.key !== 'Enter')
                val = val.substring(0, val.length - 1);
            if (state.tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
                toast.warn("El valor ya fue ingresado");
                return;
            }
            if (validarInput(val)) {
                setState({tags: [...state.tags, val]});
                e.target.value = null;
            }
        } else if (e.key === 'Backspace' && !val) {
            removeTag(state.tags.length - 1);
        }
    };

    return (
        <>
            <div id="contenedor-tags" className="contenedor-scroll-tags-input scroll-awesome input-tag">
                <ul className="input-tag__tags">
                    {state.tags && state.tags.map((tag, i) => (
                        <li key={i}>
                            {tag}
                            <button type="button" onClick={() => {
                                removeTag(i);
                            }}>+
                            </button>
                        </li>
                    ))}
                    <li className="input-tag__tags__input"><input type="text" onKeyUp={(e) => inputKeyDown(e)}/></li>
                </ul>
            </div>
        </>
    );


};

export default TagsInput;