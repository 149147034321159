import React from "react";
import styled from "styled-components";

const InputMultiCheckboxAndRadioCard = ({
  label,
  radioValue = "",
  values = [],
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
}) => {
  const handleCheck = (data) => {
    let id = data.target.value;
    let isCheked = data.target.checked;
    let temp = isCheked
      ? [...(values ?? []), id]
      : values.filter((o) => o !== id);
    let selected = {
      target: {
        value: temp,
        name,
      },
    };
    onChange(selected);
  }

  const handleChangeRadioButton = ({ target }) => {
    const { name, value } = target;
    let selected = {
      target: {
        value,
        name,
      },
    };
    onChange(selected);
  }

  return (
    <>
      <div className="form-group">
        <label className={`col-sm-12 pl-1 col-form-label modal-label ${isRequired ? "required" : ""}`}>
          {label}
        </label>
      </div>
      <table className="w-100">
        <tbody>
          <tr>
            <td className="px-3">
              <input id="Cargos" type="checkbox" name={name} value="Cargos" checked={values?.includes("Cargos") ?? false} onChange={handleCheck} disabled={isDisabled} className="" />
              <label className="pl-3" htmlFor={`Cargos`}>
                Cargos
              </label>
            </td>
            <td className="px-3">
              <input id="EsCuponBloqueado" type="checkbox" name={name} value="EsCuponBloqueado" checked={values?.includes("EsCuponBloqueado") ?? false} onChange={handleCheck} disabled={isDisabled} className="" />
              <label className="pl-3" htmlFor={`EsCuponBloqueado`}>
                Bloqueado
              </label>
            </td>
          </tr>
          <tr>
            <td className="px-3">
              <input id="Horario" type="checkbox" name={name} value="Horario" checked={values?.includes("Horario") ?? false} onChange={handleCheck} disabled={isDisabled} className="" />
              <label className="pl-3" htmlFor={`Horario`}>
                Horario
              </label>
            </td>
            <td className="px-3">
              <input id="TipoDeCondicion" type="checkbox" name={name} value="TipoDeCondicion" checked={values?.includes("TipoDeCondicion") ?? false} onChange={handleCheck} disabled={isDisabled} className="" />
              <label className="pl-3" htmlFor={`TipoDeCondicion`}>
                Condición
              </label>
            </td>
          </tr>
          <tr>
            <td className="px-3">
              <input id="SustentoPlantillaCupon" type="checkbox" name={name} value="SustentoPlantillaCupon" checked={values?.includes("SustentoPlantillaCupon") ?? false} onChange={handleCheck} disabled={isDisabled} className="" />
              <label className="pl-3" htmlFor={`SustentoPlantillaCupon`}>
                Sustento
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="form-group d-flex align-items-center justify-content-center flex-column" style={{ gap: "10px" }}>
        <fieldset className='d-flex justify-content-between col-sm-6 px-3 py-2' style={{ border: "1px solid #9c9c9c" }}>
          <Legend>Elegir uno:</Legend>
          <div className="">
            <input id="CantidadDias" type='radio' name="CantidadDiasYFecha" value="CantidadDias" checked={radioValue === "CantidadDias"} className='mt-2' onChange={handleChangeRadioButton}/>
            <label className='pl-3' htmlFor={`CantidadDias`}>
              Cantidad días
            </label>
          </div>
          <div className="">
            <input id="Fecha" type='radio' name="CantidadDiasYFecha" value="Fecha" checked={radioValue === "Fecha"} className='mt-2' onChange={handleChangeRadioButton}/>
            <label className='pl-3' htmlFor={`Fecha`}>
              Fecha
            </label>
          </div>
        </fieldset>
      </div>
      <div className="d-flex justify-content-center col-sm-10 ml-4 pl-3">
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </>
  )
}

export default InputMultiCheckboxAndRadioCard;

const Legend = styled.legend`
  width: auto;
  padding: 0 5px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: bolder;
  color: #00659D;
`;