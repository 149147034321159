import React from "react";
import { getRelatedDataTypesAPI } from "../../../consumers/backendApisUrls";
import InputUploadFile from "../../../components/modalComponents/InputUploadFile";
import InputSelect from "../../../components/modalComponents/InputSelect";
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade'
import ModalForm from "../../../components/modalComponents/ModalForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const ConvenioHorarioLactancia = ({
  KEYS,
  SECTION_NAME,
  showModal,
  isLoading,
  isGeneratingTemplate,
  handleSubmit,
  label,
  formValues,
  setFormValues,
  selectProps,
  commonProps,
  template,
  getTemplate,
  puedeEditarHorarios,
  puedeVerArchivos,
  footarMessage = null,
}) => {
  const loadingMessage = (
    <>
      Se está generando la plantilla...{" "}
      <FontAwesomeIcon
        icon="spinner"
        transform="left-9"
        className="ml-3 spinner"
      />
    </>
  );

  return (
    <ModalForm
      titulo="Convenio de horario de lactancia"
      width="890px"
      showModal={showModal}
      isSubmitting={isLoading || isGeneratingTemplate}
      saveButtonDisabled={isLoading || isGeneratingTemplate}
      onSubmit={handleSubmit}
      label={label}
      footerExtraMessage={footarMessage}>
      <InputSelect
        label="Tipo de Horario de Lactancia"
        {...selectProps(KEYS.idTipoHorarioLactancia, [KEYS.idHorarioLactancia])}
        isDisabled={!puedeEditarHorarios || isLoading}
        isRequired
      />
      <InputSelectCascade
        label="Horario de Lactancia"
        dependendOption={formValues[KEYS.idTipoHorarioLactancia]}
        api={getRelatedDataTypesAPI}
        {...commonProps(KEYS.idHorarioLactancia)}
        isDisabled={!puedeEditarHorarios || isLoading}
        isRequired
      />
      {puedeVerArchivos && (
        <>
          <InputUploadFile
            label="Plantilla Carta Convenio"
            name="Plantilla Carta Convenio"
            uploadFile={template}
            sectionName={SECTION_NAME}
            isUploading={isGeneratingTemplate}
            isIcon={true}
            isDisabled={true}
            onClick={() => getTemplate(formValues)}
            NoFileMessage={loadingMessage}
          />
          <InputUploadFile
            label="Carta Convenio"
            name={[KEYS.cartaConvenioHorarioLactancia]}
            uploadFile={formValues[KEYS.cartaConvenioHorarioLactancia]}
            setUploadFile={(res) =>
              setFormValues({
                ...formValues,
                [KEYS.cartaConvenioHorarioLactancia]: res,
              })
            }
            isUploading={isGeneratingTemplate}
            sectionName={SECTION_NAME}
            validExtensions={["pdf"]}
            isDisabled={isGeneratingTemplate || isLoading}
            isRequired
          />
        </>
      )}
    </ModalForm>
  );
};

export default ConvenioHorarioLactancia;
