import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { cleanDropdown, handleFind, handleFindDropdownSelected, initialAllOptionPromises } from '../../../../../helper/utils';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../../../hooks/useManagePostRequest/useManagePostRequest';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { useGetRequest } from '../../../../../hooks/useGetRequest/useGetRequest';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import InputDate from '../../../../../components/modalComponents/InputDate';
import InputText from '../../../../../components/modalComponents/InputText';
import FooterModal from '../../../../../components/modalComponents/FooterModal';
import InputSelect from '../../../../../components/modalComponents/InputSelect';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import InputSearchAsync from '../../../../../components/modalComponents/InputSearchAsync';
import InputSelectCascade from '../../../../../components/modalComponents/InputSelectCascade';
import InputSelectCascadePost from '../../../../../components/modalComponents/InputSelectCascadePost';
import { CARGOS, FORMAS_INGRESO, GRADOS_DESARROLLO, KEYS_DATOS_EMPRESA_NI, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS, SEDES, TIPOS_CONTRATO, TIPOS_TRABAJADOR, TIPO_DE_CONTRATO_INDEFINIDO_ID } from '../../../../../constants/hardCodedConstants';
import { getAllCargosAPI, getAllSedesAPI, getAreasByGerenciaAPI, getDatosEmpresaGestionAPI, getDivisionesAPI, getEspecialidadesAPI, getFormasIngresoAPI, getGerenciasByDivisionAPI, getGradoDesarrolloAPI, getSeccionesByAreaAPI, getTiposContrato, saveDatosEmpresaAPI, getAllJornadasLaboralesAPI, getEspecialidadSeleccionAPI, buscarColaboradorAPI, getGruposOcupacionalesAPI, getTiposLicenciaAPI, getPeriodosPruebaAPI, getPerfilPuestoByCargoAPI, consultarSedeNuevoIngresoGestionAPI, getCondicionesAcademicasAPI, getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI, getAllTiposTrabajadorAPI, getRelatedDataTypesAPI, getListaFuentesReclutamientoAPI, getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI } from '../../../../../consumers/backendApisUrls';

const DatosEmpresa = ({ idNuevosIngresosEmpleado, idParent: ID_PARENT, commonStates, setCommonStates, isColaborador, inputDisabled }) => {

  const SECTION_NAME = "EditarDatosEmpresaFichaNuevosIngresos";
  const IDS_TIPO_EMPLEADO_PRACTICANTE = [TIPOS_TRABAJADOR.PRACTICANTE_PRE, TIPOS_TRABAJADOR.PRACTICANTE_PRO];
  const KEYS_INIT = 
  {Cargo: "IdCargo", Sede: "IdSede", SedeSecundaria: "IdSedeSecundaria", Division: "IdDivision", EspecialidadSeleccion: "IdEspecialidadSeleccion", 
    GradoDesarrollo: "IdGradoDesarrollo", TipoEmpleado: "IdTipoEmpleado", TipoContrato: "IdTipoContrato", FormaIngreso: "IdFormaIngreso",
     TipoTrabajador: "IdTipoTrabajador", FuenteReclutamiento: "IdFuenteReclutamiento"};

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [allIdGerencia, setAllIdGerencia] = useState([]);
  const [allIdArea, setAllIdArea] = useState([]);
  const [allIdSeccion, setAllIdSeccion] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();

  const [executePost] = useManagePostRequest();

  const [executeGetOne] = useGetRequest();

  const allGetPromises = () => {
    return [
      getOptions(getAllCargosAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getDivisionesAPI()),
      getOptions(getEspecialidadSeleccionAPI()),
      getOptions(getGradoDesarrolloAPI()),
      getOptions(getAllJornadasLaboralesAPI()),
      getOptions(getTiposContrato()),
      getOptions(getFormasIngresoAPI()),
      //getOptions(getGruposOcupacionalesAPI()),
      getOptions(getAllTiposTrabajadorAPI()),
      getOptions(getListaFuentesReclutamientoAPI()),
    ]
  }

  const init = async () => {
    try {
      let { data } = await manageSearch();
      initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), (res) => successInitCallback(res, data));
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    const api = getDatosEmpresaGestionAPI(idNuevosIngresosEmpleado);
    return await executeGet(api, successManageSearchCallback)
  }

  const successManageSearchCallback = ({ data }) => {
    const IdFormaIngreso = data.IdFormaIngreso 
    // || FORMAS_INGRESO.BANCO_CURRICULUM;
    setFormValues({ ...data, IdFormaIngreso });
    let { IdTipoContrato, IdTipoEmpleado, IdSede } = data;
    setCommonStates(lastData =>  ({ ...lastData, IdTipoContrato, IdTipoEmpleado, IdSede, isLoading: isLoading}));
  }

  const successInitCallback = async (res, initValues) => {
    const selectDiasPartTime = {key: "CantDiasSemana", value: diasPartTime};
    res.push(selectDiasPartTime);
    setAllDropdowns(res);
    initValues?.IdGerencia && setAllIdGerencia(await getOptions(getGerenciasByDivisionAPI(initValues.IdDivision)));
    initValues?.IdArea && setAllIdArea(await getOptions(getAreasByGerenciaAPI(initValues.IdGerencia)));
    initValues?.IdSeccion && setAllIdSeccion(await getOptions(getSeccionesByAreaAPI(initValues.IdArea)));
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const api = saveDatosEmpresaAPI();
      const payload = getPayload();
      await submitForm(KEYS_DATOS_EMPRESA_NI, api, payload, SECTION_NAME, () => manageSearch(), true, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }

  const getPayload = () => {
    const { FechaIngresoEmpresa, ...payload} = formValues;

    return {
      ...payload,
      FechaIngresoEmpresaValue: FechaIngresoEmpresa,
      FechaInicioContrato: FechaIngresoEmpresa,
      FechaInicioContratoValue: FechaIngresoEmpresa,
      FechaIngresoEmpresa,
    }
  }

  const handleChangeSelect = (res, key) => {
    setFormValues(lastData => ({...lastData, [key]: res.target?.value?.value}));
  }

  const cleanDropdownSelected = (keys) => {
    keys.map(o => setFormValues(lastData => ({...lastData, [o]: ""})));
  }

  const getDependetsTipoContrato = () => {
    return {
      IdTipoTrabajador: formValues[KEYS_INIT.TipoTrabajador],
      IdJornadaLaboral: formValues[KEYS_INIT.TipoEmpleado],
    }
  }

  const getJefeMatricial = async () => {
    const api = getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI();

    const payload = {
      "IdArea": formValues.IdArea,
      "IdCargo": formValues.IdCargo,
      "IdDepartamento": formValues.IdGerencia
    }
    
    return await executePost(api, payload, successJefeMatricialCallback)
  }

  const successJefeMatricialCallback = ({ data }) => {
    if (formValues?.EmpleadoMatricialSuperior !== data?.EmpleadoMatricialSuperior){
      setFormValues({ ...formValues, EmpleadoMatricialSuperior: (data?.EmpleadoMatricialSuperior ?? "-")});
    }
  }

  useDidMountEffect(() => {
    if(!isLoading) {
      getJefeMatricial();
    }
  }, [formValues.IdGerencia, formValues.IdArea, formValues.IdCargo ])

  useDidMountEffect( async() => {
    if(formValues.IdDivision && !isLoading) {
      cleanDropdownSelected(["IdGerencia", "IdArea", "IdSeccion"]);
      setAllIdGerencia(await getOptions(getGerenciasByDivisionAPI(formValues.IdDivision)));
    };
  }, [formValues.IdDivision])

  useDidMountEffect( async() => {
    if(formValues.IdGerencia && !isLoading) {
      cleanDropdownSelected(["IdArea", "IdSeccion"]);
      setAllIdArea(await getOptions(getAreasByGerenciaAPI(formValues.IdGerencia)));
    };
  }, [formValues.IdGerencia])
  
  useDidMountEffect( async() => {
    if(formValues.IdArea && !isLoading) {
      cleanDropdownSelected(["IdSeccion"]);
      setAllIdSeccion(await getOptions(getSeccionesByAreaAPI(formValues.IdArea)));
    };
  }, [formValues.IdArea])

  useDidMountEffect(() => {
    if (!commonStates?.isLoading){
      setFormValues(lastData => ({...lastData, "CantDiasSemana": null}));
      cleanDropdown(setFormValues, ["CantDiasSemana", "NombreProyectoServicio", "IdColaboradorSuplencia", "ColaboradorSuplencia", "IdTipoLicencia", "DiasLicencia", "IdPeriodoPrueba", "Profesion", "Especialidad", "IdCondicionAcademica"]);
      cleanDropdown(setCommonStates, ["CantDiasSemana", "NombreProyectoServicio", "IdColaboradorSuplencia", "ColaboradorSuplencia", "IdTipoLicencia", "DiasLicencia", "IdPeriodoPrueba", "Profesion", "Especialidad", "IdCondicionAcademica"]);
    }
  }, [commonStates.IdTipoContrato])

  useDidMountEffect(() => {
    if (!commonStates?.isLoading){
      cleanDropdown(setFormValues, ["Especialidad", "Profesion"]);
      cleanDropdown(setCommonStates, ["Especialidad", "Profesion"]);
    }
  }, [commonStates.IdSede])

  useDidMountEffect(() => {
    if(!isLoading){
      setCommonStates(lastData => ({
        ...lastData, 
        isLoading, 
        [KEYS_INIT.TipoContrato]: formValues.IdTipoContrato !== undefined ? formValues[KEYS_INIT.TipoContrato] : null
      }))
    }
  }, [formValues.IdTipoContrato])

  useDidMountEffect(() => {
    if (!isColaborador && formValues.IdCargo === CARGOS.PROFESOR){
      let IdGradoDesarrollo = formValues[KEYS_INIT.GradoDesarrollo] ?? GRADOS_DESARROLLO.INNOVA;
      setFormValues(lastData => ({...lastData, IdGradoDesarrollo}))
    }
  }, [formValues.IdCargo])

  useDidMountEffect(() => {
    if(!isLoading){
      setCommonStates(lastData => ({
        ...lastData, 
        isLoading, 
        [KEYS_INIT.Sede]: formValues.IdSede !== undefined ? formValues[KEYS_INIT.Sede] : null
      }))
    }
  }, [formValues.IdSede])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <form onSubmit={handleSubmit}>
      <CardAccordion id={OPTIONS.idDatosEmpresa} idParent={ID_PARENT} label="5. Datos de Empresa" isShow isShowGeneral={!isColaborador}>
        <div className="displayFlexWrap mt-4">
          <DivCol required={!isColaborador}><InputDate label="Fecha Ingreso Empresa" {...commonProps('FechaIngresoEmpresa')} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          {!isColaborador && 
            <DivCol required><InputDate label="Fecha Ingreso real" {...commonProps('FechaIngresoReal')} direction='column' isDisabled={inputDisabled}/></DivCol>
          }
          <DivCol required={!isColaborador}><InputSelect label="Cargo" {...commonPropsSelect("IdCargo", ["IdEspecialidadSeleccion", "IdGradoDesarrollo"])} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelect label="Sede" {...commonPropsSelect("IdSede")} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol><InputSelect label="Sede Secundaria" {...commonPropsSelect("IdSedeSecundaria")} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelect label="División" {...commonPropsSelect("IdDivision")} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelect label="Gerencia" name="IdGerencia" options={allIdGerencia} value={allIdGerencia?.find(o => o.value == formValues.IdGerencia)} onChange={(res) => handleChangeSelect(res, "IdGerencia")} sectionName={SECTION_NAME} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelect label="Área" name="IdArea" options={allIdArea} value={allIdArea?.find(o => o.value == formValues.IdArea)} onChange={(res) => handleChangeSelect(res, "IdArea")} sectionName={SECTION_NAME} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>      
          <DivCol required={!isColaborador}><InputSelect label="Sección" name="IdSeccion" options={allIdSeccion} value={allIdSeccion?.find(o => o.value == formValues.IdSeccion)} onChange={(res) => handleChangeSelect(res, "IdSeccion")} sectionName={SECTION_NAME} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          {(!isColaborador && (formValues.IdCargo === CARGOS.PROFESOR || formValues.IdCargo === CARGOS.ASISTENTE_EDUCACION)) &&
            <>
              <DivCol required><InputSelect label="Especialidad Selección Ingreso" {...commonPropsSelect("IdEspecialidadSeleccion")} direction='column' isDisabled={inputDisabled}/></DivCol>
              <DivCol ><InputSelect label="Grado de Desarrollo Ingreso" {...commonPropsSelect("IdGradoDesarrollo")} direction='column' isDisabled={inputDisabled}/></DivCol>
            </>
          }
          <DivCol required={!isColaborador}><InputText label="Remuneración" {...commonProps("Remuneracion")} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelect label="Tipo Trabajador" {...commonPropsSelect("IdTipoTrabajador", ["IdTipoContrato", "IdRegimenLaboral"])} isDisabled={isColaborador || inputDisabled} direction="column"/></DivCol>
          <DivCol required={!isColaborador}><InputSelect label="Jornada Laboral" {...commonPropsSelect("IdTipoEmpleado", ["IdTipoContrato", "IdRegimenLaboral"])} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelectCascadePost label="Tipo Contrato" dependendOption={getDependetsTipoContrato()} api={getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI} {...commonPropsSelect("IdTipoContrato", ["IdRegimenLaboral", "FechaVencimientoContrato", "CantDiasSemana", "NombreProyectoServicio", "IdColaboradorSuplencia", "IdTipoLicencia", "DiasLicencia", "IdPeriodoPrueba", "IdCondicionAcademica", "Profesion", "Especialidad"])} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          <DivCol required={!isColaborador}><InputSelectCascade label="Régimen Laboral" dependendOption={formValues[KEYS_INIT.TipoContrato]} api={getRelatedDataTypesAPI} {...commonProps("IdRegimenLaboral")} isDisabled={isColaborador || inputDisabled} direction="column"/></DivCol>
          <DivCol required={!isColaborador}><InputDate label="Fecha de Inicio de Contrato" value={formValues.FechaIngresoEmpresa} isDisabled direction='column'/></DivCol>
          {formValues[KEYS_INIT.TipoContrato] !== TIPO_DE_CONTRATO_INDEFINIDO_ID &&
            <DivCol required={!isColaborador}><InputDate label="Fecha de Vencimiento de Contrato" {...commonProps('FechaVencimientoContrato')} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          }
          {!isColaborador && 
            <>
              <DivCol><InputSelect label="Forma de Ingreso" {...commonPropsSelect("IdFormaIngreso")} direction='column' isDisabled={inputDisabled}/></DivCol>
              <DivCol ><InputText label="Correo Corporativo" {...commonProps("CorreoCorporativo")} direction='column' isDisabled={inputDisabled}/></DivCol>
            </>
          }
          <DivCol required={!isColaborador}><InputSearchAsync typeGet direction="column" label="Empleado Líder" api={buscarColaboradorAPI} isDisabled={isColaborador || inputDisabled} valueText={formValues.EmpleadoLider} {...commonProps('IdEmpleadoLider')}/></DivCol>
          {/* <DivCol><InputSearchAsync typeGet direction="column" label="Empleado Líder Matricial" api={buscarColaboradorAPI} isDisabled={isColaborador || inputDisabled} valueText={formValues.EmpleadoMatricialSuperior} {...commonProps('IdEmpleadoMatricialSuperior')}/></DivCol> */}

          <DivCol><InputText label="Empleado Líder Matricial" {...commonProps("EmpleadoMatricialSuperior")} isDisabled={true} direction='column'/></DivCol>

          <DivCol><InputSelect label="Fuente Reclutamiento" {...commonPropsSelect("IdFuenteReclutamiento")} isDisabled={isColaborador || inputDisabled} direction='column'/></DivCol>
          {((formValues.IdSede === SEDES.BACK_OFFICE && !IDS_TIPO_EMPLEADO_PRACTICANTE.includes(formValues[KEYS_INIT.TipoTrabajador])) || TIPOS_CONTRATO.PART_TIME.includes(commonStates?.IdTipoContrato) || commonStates?.IdTipoContrato === TIPOS_CONTRATO.OBRA_SERVICIO || commonStates?.IdTipoContrato === TIPOS_CONTRATO.SUPLENCIA || commonStates?.IdTipoContrato === TIPOS_CONTRATO.INCREMENTO_ACTIVIDAD || commonStates?.IdTipoContrato === TIPOS_CONTRATO.NECESIDAD_MERCADO) &&
              <div className="col-sm-12 ficha-datos-empresa-seccion-title"></div>
          }
          {((commonStates?.IdSede === SEDES.BACK_OFFICE && !IDS_TIPO_EMPLEADO_PRACTICANTE.includes(formValues[KEYS_INIT.TipoTrabajador])) || (commonStates?.IdTipoContrato === TIPOS_CONTRATO.SUPLENCIA) || (commonStates?.IdTipoContrato === TIPOS_CONTRATO.NECESIDAD_MERCADO)) && 
            <DivCol required><InputText label="Profesión del Candidato" {...commonProps("Profesion")} direction='column' isDisabled={inputDisabled}/></DivCol>
          }
          {((commonStates?.IdSede === SEDES.BACK_OFFICE && !IDS_TIPO_EMPLEADO_PRACTICANTE.includes(formValues[KEYS_INIT.TipoTrabajador])) || (commonStates?.IdTipoContrato === TIPOS_CONTRATO.NECESIDAD_MERCADO)) &&
            <DivCol required><InputText label="Especialidad del Candidato" {...commonProps("Especialidad")} direction='column' isDisabled={inputDisabled}/></DivCol>
          }
          {TIPOS_CONTRATO.PART_TIME.includes(commonStates?.IdTipoContrato) &&
            <DivCol><InputSelect label="Cant.Días a la semana Part-Time" {...commonPropsSelect("CantDiasSemana")} direction='column' isDisabled={inputDisabled}/></DivCol>
          }
          {commonStates?.IdTipoContrato === TIPOS_CONTRATO.OBRA_SERVICIO &&
            <DivCol required><InputText label="Nombre del proyecto o Servicio" {...commonProps("NombreProyectoServicio")} direction="column" isDisabled={inputDisabled}/></DivCol>
          }
          {commonStates?.IdTipoContrato === TIPOS_CONTRATO.SUPLENCIA &&
            <>
              <DivCol required><InputSearchAsync typeGet label="Colaborador Suplencia" api={buscarColaboradorAPI} valueText={formValues.ColaboradorSuplencia} {...commonProps('IdColaboradorSuplencia')} direction="column" isDisabled={inputDisabled}/></DivCol>
              <DivCol required><InputSelectCascade isIdSearch={false} label="Tipo de Licencia" dependendOption={commonStates?.IdTipoContrato} api={getTiposLicenciaAPI} {...commonProps("IdTipoLicencia")} direction="column" isDisabled={inputDisabled}/></DivCol>
              <DivCol required><InputText label="Días de licencia" {...commonProps("DiasLicencia")} direction="column" isDisabled={inputDisabled}/></DivCol>
              <DivCol required><InputSelectCascade isIdSearch={false} label="Condición Académica" dependendOption={commonStates?.IdTipoContrato} api={getCondicionesAcademicasAPI} {...commonProps("IdCondicionAcademica")} direction="column" isDisabled={inputDisabled}/></DivCol>
            </>
          }
          {commonStates?.IdTipoContrato === TIPOS_CONTRATO.INCREMENTO_ACTIVIDAD &&
            <DivCol required><InputSelectCascade isIdSearch={false} label="Periodo de Prueba" dependendOption={commonStates?.IdTipoContrato} api={getPeriodosPruebaAPI} {...commonProps("IdPeriodoPrueba")} direction="column" isDisabled={inputDisabled}/></DivCol>
          }
        </div>
        {(!inputDisabled && !isColaborador) && <FooterModal isDisabled={isSending}/>}
      </CardAccordion>
    </form>
  )
}

export default DatosEmpresa;

const DivCol = ({ required, children }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""}`}>
      {children}
    </div>
  )
}

const diasPartTime = [
  {label: "Un (1) día", value: "1"}, 
  {label: "Dos (2) días", value: "2"}
]