import React, {useEffect, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import {
    agregarColaboradorAPI,
    editarColaboradorAPI,
    getAllCargosAPI,
    getAllSedesAPI,
    getColaboradorById, getDropDownColaboradorAPI,
    getEditarColaboradorAPI, getEstadoEtapaProcesarYAsignarGruposAPI, getGruposActivosProcesoCierreAPI, getProcesoCierreCondicionesAPI,
    getProcesoCierreTiposContratoAPI
} from '../../../consumers/backendApisUrls';
import {mostrarMensajeDeErrorConToast, responseCode200} from '../../../consumers/httpRequiestsUtils';
import {callCallbackAPIGeneric, focusElement, getCurrentYear, jsonNoEstaVacio, notNullAndNotUndefinded} from '../../../helper/utils';
import {useGetRequest} from '../../../hooks/useGetRequest/useGetRequest';
import {useForm} from '../../../hooks/useForm/UseForm';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import SelectInput from '../../../components/Select/SelectInput';
import {useGetOptionsSelect} from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {CONDICIONES_CIERRE_DE_ANIO, ESTADOS_ETAPAS_CIERRE_DE_ANIO, TIPOS_CONTRATO} from "../../../constants/hardCodedConstants";
import {useManageGetRequest} from "../../../hooks/useManageGetRequest/useManageGetRequest";

const AgregarEditarColaborador = ({showModal, idProcesoCierreEmpleado, refreshTable}) => {
    const sectionName = "AgregarEditarColaborador";
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [isResearchColaboradorById, setIsResearchColaboradorById] = useState(false);
    const [fichaColaborador, setFichaColaborador] = useState({});
    const [fichaColaboradorEditar, setFichaColaboradorEditar] = useState({});
    const [jsonSelectedCondicion, setJsonSelectedCondicion] = useState({});
    const [jsonSelectedTipoContrato, setJsonSelectedTipoContrato] = useState({});
    const [jsonSelectedCargo, setJsonSelectedCargo] = useState({});
    const [jsonSelectedSede, setJsonSelectedSede] = useState({});
    const [jsonSelectedResponsable, setJsonSelectedResponsable] = useState({});
    const [jsonSelectedGruposActivos, setJsonSelectedGruposActivos] = useState({});

    const [getOptions] = useGetOptionsSelect();

    const [condicionesOptions, setCondicionesOptions] = useState([]);
    const [tiposContratosOptions, setTiposContratosOptions] = useState([]);
    const [cargosOptions, setCargosOptions] = useState([]);
    const [sedesOptions, setSedesOptions] = useState([]);
    const [gruposActivosOptions, setGruposActivosOptions] = useState([]);

    const [modoIfRegistrarIsTrueElseEditarIsFalse, setModoIfRegistrarIsTrueElseEditarIsFalse] = useState();
    const [tituloModal, setTituloModal] = useState("");
    const [submitForm] = useSubmitForm();

    const [nextYear] = useState(getCurrentYear() + 1);

    const [disableInputs, setDisableInputs] = useState(true);
    const [disableInputsVaca, setDisableInputsVaca] = useState(true);
    const [disableInputResponsable, setDisableInputResponsable] = useState(false);
    const [disableInputCondicion, setDisableInputCondicion] = useState(false);
    const [disableInputFechaFin, setDisableInputFechaFin] = useState(false);
    const [disableInputFechaInicio, setDisableInputFechaInicio] = useState(false);

    const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState({});

    const [manageGetRequest] = useManageGetRequest();

    const [showGrupo, setShowGrupo] = useState();

    const [showDiasJornada , setShowDiasJornada] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (idProcesoCierreEmpleado != undefined) {
            setModoIfRegistrarIsTrueElseEditarIsFalse(false);
        } else {
            setModoIfRegistrarIsTrueElseEditarIsFalse(true);
        }
    }, [idProcesoCierreEmpleado]);

    useDidMountEffect(() => {
        if (notNullAndNotUndefinded(jsonSelectedTipoContrato) && jsonNoEstaVacio(jsonSelectedTipoContrato)) {
            manageUpdatePeriodoByTipoContrato();
            console.log("show dias jornada laboral by jsonSelectedTipoContrato: ", jsonSelectedTipoContrato)

            if (TIPOS_CONTRATO.PART_TIME.includes(jsonSelectedTipoContrato?.value)){
                setShowDiasJornada(true);
            }else{
                setShowDiasJornada(false);
            }
        }
    }, [jsonSelectedTipoContrato]);


    useDidMountEffect(() => {
        if (isResearchColaboradorById && notNullAndNotUndefinded(jsonSelectedCondicion) && jsonNoEstaVacio(jsonSelectedCondicion)) {
            manageJsonSelectedCondition();
        }
    }, [jsonSelectedCondicion]);

    useDidMountEffect(() => {
        if (notNullAndNotUndefinded(jsonSelectedTipoContrato) && notNullAndNotUndefinded(jsonSelectedCondicion) && jsonNoEstaVacio(jsonSelectedTipoContrato) && jsonNoEstaVacio(jsonSelectedCondicion)) {
            actualizarFormularioSegunDatos();
        }

    }, [jsonSelectedTipoContrato, jsonSelectedCondicion]);

    useDidMountEffect(() => {
        if (modoIfRegistrarIsTrueElseEditarIsFalse === true) {
            setTituloModal("Agregar Colaborador")
        }

        if (modoIfRegistrarIsTrueElseEditarIsFalse === false) {
            setTituloModal("Editar Colaborador")
        }
    }, [modoIfRegistrarIsTrueElseEditarIsFalse]);

    useDidMountEffect(() => {
        if (notNullAndNotUndefinded(fichaColaborador) && jsonNoEstaVacio(fichaColaborador)) {

            setModalFormValues();
            if (modoIfRegistrarIsTrueElseEditarIsFalse === true) {
                setJsonSelectedResponsable({value: fichaColaborador?.IdEmpleadoSuperior, label: fichaColaborador?.EmpleadoSuperior})
            } else if (modoIfRegistrarIsTrueElseEditarIsFalse === false) {
                setJsonSelectedResponsable({value: fichaColaboradorEditar?.IdEmpleadoResponsable, label: fichaColaboradorEditar?.EmpleadoResponsable})
            }
            actualizarFormularioSegunDatos();
        }

    }, [fichaColaborador, jsonSelectedCondicion]);

    const enableResearchColaborador = () => {
        setIsResearchColaboradorById(true);
    };

    const manageUpdatePeriodoByTipoContrato = function () {
        if (jsonSelectedTipoContrato?.IdEntity === TIPOS_CONTRATO.Indefinido.IdEntity) {
            setDisableInputFechaFin(true);
            setFormValues({...formValues, FechaFin: ""});

            if (fichaColaborador?.IdEntityTipoContratoAnterior === TIPOS_CONTRATO.Indefinido.IdEntity) {
                setFormValues({...formValues, FechaInicio: ""});
                setDisableInputFechaInicio(true);
            }

        } else if (notNullAndNotUndefinded(jsonSelectedTipoContrato?.value)) {
            setDisableInputFechaFin(false);
            setDisableInputFechaInicio(false);
            if (modoIfRegistrarIsTrueElseEditarIsFalse === false) {
                setFormValues({...formValues, FechaFin: fichaColaborador?.FechaFinValue, FechaInicio: fichaColaborador?.FechaInicioValue})
            }
        }
        actualizarFormularioSegunDatos();
    }

    const successGetColaboradorData = function (response) {
        setFichaColaborador({...fichaColaborador, ...response?.data});
    }

    const manageJsonSelectedCondition = async function () {
        if ((jsonSelectedCondicion?.value === CONDICIONES_CIERRE_DE_ANIO["Cese/Ingreso"].id) || (jsonSelectedCondicion?.value === CONDICIONES_CIERRE_DE_ANIO.Renueva.id)) {

            if (modoIfRegistrarIsTrueElseEditarIsFalse === true && empleadoSeleccionado?.value) {
                await manageGetRequest(getColaboradorById(empleadoSeleccionado.value), successGetColaboradorData, false);
            }

            if (modoIfRegistrarIsTrueElseEditarIsFalse === false && fichaColaborador?.IdEmpleado) {
                await manageGetRequest(getColaboradorById(fichaColaborador?.IdEmpleado), successGetColaboradorData, false);
            }
        }
    }

    const actualizarFormularioSegunDatos = () => {
        setFormValues({...formValues, FechaInicio: fichaColaborador?.FechaInicioValue, FechaFin: fichaColaborador?.FechaFinValue, 
            Remuneracion: fichaColaborador?.Remuneracion, RequierePlantillaCargaManual: fichaColaborador?.RequierePlantillaCargaManual,
            NoRequiereCartaIncremento: fichaColaborador?.NoRequiereCartaIncremento,
             DiasPorSemanaJornadaLaboral: fichaColaborador?.DiasPorSemanaJornadaLaboral}
            );
        desactivarSiEsNoRenuevaOVacaciones();
        if (jsonSelectedCondicion?.value == CONDICIONES_CIERRE_DE_ANIO.Renueva.id &&
            jsonSelectedTipoContrato?.idEntity == TIPOS_CONTRATO.Indefinido.IdEntity) {
            validarCamposDeFechasParaContratosIndefiniosYCondicionRenueva();
        } else if (jsonSelectedCondicion?.value == CONDICIONES_CIERRE_DE_ANIO["Cese/Ingreso"].id) {
            setDisableInputFechaInicio(false);
            setDisableInputFechaFin(false);
        }
    };

    const desactivarSiEsNoRenuevaOVacaciones = () => {
        if ((jsonSelectedCondicion?.value === CONDICIONES_CIERRE_DE_ANIO.NoRenueva.id) || (jsonSelectedCondicion?.value === CONDICIONES_CIERRE_DE_ANIO.Vacaciones.id)) {
            setDisableInputs(true);
            setDisableInputFechaFin(true);
            setDisableInputFechaInicio(true);
            setFormValues({...formValues, FechaInicio: "", FechaFin: ""})
            setJsonSelectedCargo({});
            setJsonSelectedSede({});
            if (jsonNoEstaVacio(jsonSelectedTipoContrato)) {
                setJsonSelectedTipoContrato({});
            }

            if (jsonSelectedCondicion?.value === CONDICIONES_CIERRE_DE_ANIO.Vacaciones.id)
            {
                setDisableInputsVaca(false)
            }
            else
            {
                setDisableInputsVaca(true)
                setFormValues({...formValues, Remuneracion: ""})
            }
        } else {
            setDisableInputs(false);
            setDisableInputsVaca(false)
        }
    };

    const validarCamposDeFechasParaContratosIndefiniosYCondicionRenueva = () => {
        let idTipoContratoAnterior = modoIfRegistrarIsTrueElseEditarIsFalse ? fichaColaborador?.IdEntityTipoContratoAnterior : fichaColaboradorEditar?.IdEntityTipoContratoAnterior;
        if (idTipoContratoAnterior == TIPOS_CONTRATO.Indefinido.IdEntity) {
            setFormValues({...formValues, ...{FechaInicio: "", FechaFin: ""}});
            setDisableInputFechaInicio(true);
            setDisableInputFechaFin(true);
        } else {
            setFormValues({...formValues, FechaFin: ""});
            setDisableInputFechaInicio(false);
            setDisableInputFechaFin(true);
        }
    };


    const setModalFormValues = async function () {
        let tiposContratosOptionsTemp = (tiposContratosOptions.length === 0) ? await getOptions(getProcesoCierreTiposContratoAPI()) : tiposContratosOptions;
        let cargosOptionsTemp = (cargosOptions.length === 0) ? await getOptions(getAllCargosAPI()) : cargosOptions;
        let sedesOptionsTemp = (sedesOptions.length === 0) ? await getOptions(getAllSedesAPI()) : sedesOptions
        let condicionesOptionsTemp = (condicionesOptions.length === 0) ? await getOptions(getProcesoCierreCondicionesAPI()) : condicionesOptions;

        setValuesModal(tiposContratosOptionsTemp, cargosOptionsTemp, sedesOptionsTemp, condicionesOptionsTemp);
    }

    const setValuesModal = function (tiposContratosOptions, cargosOptions, sedesOptions, condicionesOptions) {
        if (jsonSelectedCondicion?.value == undefined) {
            setJsonSelectedCondicion(condicionesOptions.find(o => o.value == fichaColaborador?.IdCondicion));
        }

        if ((jsonSelectedCondicion?.value !== CONDICIONES_CIERRE_DE_ANIO.NoRenueva.id) && (jsonSelectedCondicion?.value !== CONDICIONES_CIERRE_DE_ANIO.Vacaciones.id)) {
            setJsonSelectedTipoContrato(tiposContratosOptions.find(o => o.value == fichaColaborador?.IdTipoContrato));
            setJsonSelectedCargo(cargosOptions.find(o => o.value == fichaColaborador?.IdCargo));
            setJsonSelectedSede(sedesOptions.find(o => o.value == fichaColaborador?.IdSede));

            let payload = {
                Remuneracion: fichaColaborador?.Remuneracion,
                FechaInicio: fichaColaborador?.FechaInicioValue,
                FechaFin: fichaColaborador?.FechaFinValue,
                RequierePlantillaCargaManual: fichaColaborador?.RequierePlantillaCargaManual,
                NoRequiereCartaIncremento: fichaColaborador?.NoRequiereCartaIncremento
            };

            setFormValues(payload)
        }

        setJsonSelectedGruposActivos(gruposActivosOptions.find(o => o.value == fichaColaborador?.IdGrupo));
    }

    const successCallbackGetEditarColaborador = function (response) {
        setFichaColaboradorEditar(response);
        setFichaColaborador(response);
    }

    const init = async function () {
        setTiposContratosOptions(await getOptions(getProcesoCierreTiposContratoAPI()));
        setCondicionesOptions(await getOptions(getProcesoCierreCondicionesAPI()));
        setCargosOptions(await getOptions(getAllCargosAPI()));
        setSedesOptions(await getOptions(getAllSedesAPI()));
        setGruposActivosOptions(await getOptions(getGruposActivosProcesoCierreAPI()));
        await callCallbackAPIGeneric(executeGetRequestCallback(getEstadoEtapaProcesarYAsignarGruposAPI()), successCallBackEtapa);

        if (idProcesoCierreEmpleado) {
            await callCallbackAPIGeneric(executeGetRequestCallback(getEditarColaboradorAPI(idProcesoCierreEmpleado)), successCallbackGetEditarColaborador);
        }
    };

    const successCallBackEtapa = (data) => {
        setShowGrupo(data.IdEstadoProceso == ESTADOS_ETAPAS_CIERRE_DE_ANIO.FINALIZADO); 
    };

    const successSubmitCallback = function () {
        refreshTable();
        showModal();
    }

    const submit = async () => {
        let payload = armarPayload();
        let api = modoIfRegistrarIsTrueElseEditarIsFalse ? agregarColaboradorAPI() : editarColaboradorAPI();

        let keys = ["IdEmpleado", "IdEmpleado", "IdCondicion", "IdTipoContrato", "IdEntityTipoContrato", "IdEntityTipoContratoAnterior", "FechaInicio", "FechaFin", "IdCargo", "Remuneracion", "IdSede", "IdEmpleadoResponsable", "IdGrupo", "RequierePlantillaCargaManual", "NoRequiereCartaIncremento", "DiasPorSemanaJornadaLaboral"];

        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const armarPayload = () => {
        let payload = {
            IdCondicion: jsonSelectedCondicion?.value,
            IdTipoContrato: jsonSelectedTipoContrato?.value,
            IdEntityTipoContrato: jsonSelectedTipoContrato?.idEntity,
            IdEntityTipoContratoAnterior: modoIfRegistrarIsTrueElseEditarIsFalse ? fichaColaborador?.IdEntityTipoContratoAnterior : fichaColaboradorEditar?.IdEntityTipoContratoAnterior,

            FechaInicio: formValues?.FechaInicio,
            FechaFin: formValues?.FechaFin,
            IdCargo: jsonSelectedCargo?.value,
            Remuneracion: formValues?.Remuneracion,
            IdSede: jsonSelectedSede?.value,
            IdGrupo: jsonSelectedGruposActivos?.value,
            RequierePlantillaCargaManual: formValues?.RequierePlantillaCargaManual,
            NoRequiereCartaIncremento: formValues?.NoRequiereCartaIncremento,
            DiasPorSemanaJornadaLaboral: showDiasJornada === true ? formValues?.DiasPorSemanaJornadaLaboral : null
        }

        if (modoIfRegistrarIsTrueElseEditarIsFalse) {
            payload = {
                ...payload,
                IdEmpleado: fichaColaborador.IdEmpleado,
                IdEmpleadoResponsable: jsonSelectedResponsable?.value
            }
        } else {
            payload = {
                ...payload,
                IdProcesoCierreEmpleado: idProcesoCierreEmpleado,
                IdEmpleadoResponsable: jsonSelectedResponsable?.value

            };
        }

        return payload;
    }

    const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
        setEmpleadoSeleccionado(jsonValue);
        const response = await executeGetRequest(getColaboradorById(jsonValue.value));
        if (responseCode200(response)) {
            setFichaColaborador(response?.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const handleChangeBuscarColaboradorResponsable = async (jsonValue) => {
        setJsonSelectedResponsable(jsonValue)
    }

    return (
        <>
            <GenericModal showModal={showModal} width={"1250px"} titulo={tituloModal}>

                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row mb-0">
                            <label className="col-sm-3 col-form-label text-justify">
                                <strong>Detalle del colaborador</strong>
                            </label>
                            {modoIfRegistrarIsTrueElseEditarIsFalse === true &&
                            <div className="col-sm-9 black-color">
                                <AsyncSelectInput api={getDropDownColaboradorAPI} placeholder="Buscar por nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleado-validation-message`}></p>
                            </div>
                            }
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-2 col-form-label text-justify">
                                Colaborador
                            </label>
                            <div className="col-sm-4 black-color">
                                <p className="pt-2">{fichaColaborador?.NombreColaborador ?? fichaColaborador?.FullName}</p>
                            </div>
                            <label className="col-sm-2 col-form-label text-justify">
                                Contrato actual
                            </label>
                            <div className="col-sm-4 black-color">
                                <p className="pt-2">{fichaColaborador.TipoContrato}</p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-2 col-form-label text-justify">
                                Sede actual
                            </label>
                            <div className="col-sm-4 black-color">
                                <p className="pt-2">{fichaColaborador.Sede}</p>
                            </div>
                            <label className="col-sm-2 col-form-label text-justify">
                                Cargo actual
                            </label>
                            <div className="col-sm-4 black-color">
                                <p className="pt-2">{fichaColaborador.Cargo}</p>
                            </div>
                        </div>
                        {modoIfRegistrarIsTrueElseEditarIsFalse === false &&
                        <div className="form-group row mb-0">
                            <label className="col-sm-2 col-form-label text-justify">
                                Sueldo Anterior
                            </label>
                            <div className="col-sm-4 black-color">
                                <p className="pt-2">{fichaColaboradorEditar?.SueldoAnterior}</p>
                            </div>
                            <label className="col-sm-2 col-form-label text-justify">
                                Fecha de Ingreso
                            </label>
                            <div className="col-sm-4 black-color">
                                <p className="pt-2">{fichaColaboradorEditar?.FechaIngreso}</p>
                            </div>
                        </div>
                        }

                        {(modoIfRegistrarIsTrueElseEditarIsFalse === false || (modoIfRegistrarIsTrueElseEditarIsFalse === true && jsonNoEstaVacio(empleadoSeleccionado))) &&
                        <>
                            <div className="form-group row ">
                                <label className="col-sm-3 col-form-label text-justify">
                                    <strong>Datos renovación</strong>
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Condición
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={condicionesOptions} jsonSelectedValue={jsonSelectedCondicion} setJsonSelectedValue={setJsonSelectedCondicion} placeholder={"Seleccionar"} isDisabled={disableInputCondicion}
                                                 preFunction={enableResearchColaborador}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdCondicion-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                    Nuevo contrato
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={tiposContratosOptions} jsonSelectedValue={jsonSelectedTipoContrato} setJsonSelectedValue={setJsonSelectedTipoContrato} isDisabled={disableInputs} placeholder={"Seleccionar"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdTipoContrato-validation-message`}></p>
                                </div>
                                
                                {showDiasJornada === true && 
                                <>
                                    <label className="col-sm-2 col-form-label">
                                    Días jornada laboral
                                    </label>
                                    <div className="col-sm-4 black-color">
                                        <input type="number" className="form-control" name="DiasPorSemanaJornadaLaboral" min="1" max="7" value={formValues?.DiasPorSemanaJornadaLaboral ?? ""} onChange={handleChange} disabled={disableInputsVaca}></input>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-DiasPorSemanaJornadaLaboral-validation-message`}></p>
                                    </div>
                                </>
                                }
                             
                            </div>
                            <div className="form-group row pt-3">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Especificar periodo
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="date" className="form-control" name="FechaInicio" onChange={handleChange} value={formValues?.FechaInicio ?? ""} disabled={disableInputFechaInicio}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="date" className="form-control" name="FechaFin" onChange={handleChange} value={formValues?.FechaFin ?? ""} disabled={disableInputFechaFin}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaFin-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Nuevo cargo
                                </label>
                                <div className="col-sm-4 black-color" onClick={focusElement}>
                                    <SelectInput options={cargosOptions} jsonSelectedValue={jsonSelectedCargo} setJsonSelectedValue={setJsonSelectedCargo} isDisabled={disableInputs} placeholder={"Seleccionar"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdCargo-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label">
                                    Especificar sueldo (Carta de Incremento)
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="text" className="form-control" name="Remuneracion" value={formValues?.Remuneracion ?? ""} onChange={handleChange} disabled={disableInputsVaca}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Remuneracion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Nueva sede
                                </label>
                                <div className="col-sm-4 black-color" onClick={focusElement}>
                                    <SelectInput options={sedesOptions} jsonSelectedValue={jsonSelectedSede} setJsonSelectedValue={setJsonSelectedSede} isDisabled={disableInputs} placeholder={"Seleccionar"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdSede-validation-message`}></p>
                                </div>

                                <label className="col-sm-2 col-form-label text-justify">
                                    Responsable
                                </label>

                                <div className="col-sm-4 black-color" onClick={focusElement}>
                                    <AsyncSelectInput api={getDropDownColaboradorAPI} placeholder="Buscar por nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorResponsable} isDisabled={disableInputResponsable}
                                                      defaultValue={jsonSelectedResponsable}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleadoResponsable-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row">
                            {showGrupo &&
                                <>
                                    <label className="col-sm-2 col-form-label text-justify">
                                        Grupo
                                    </label>
                                    <div className="col-sm-4 black-color" onClick={focusElement}>
                                        <SelectInput options={gruposActivosOptions} jsonSelectedValue={jsonSelectedGruposActivos} setJsonSelectedValue={setJsonSelectedGruposActivos} placeholder={"Seleccionar"} />
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdGrupo-validation-message`}></p>
                                    </div>
                                </>
                            }
                            {modoIfRegistrarIsTrueElseEditarIsFalse === false &&
                                <>
                                    <label className="col-sm-2 col-form-label text-justify">
                                        Requiere documento "Carga Manual"
                                    </label>
                                    <div className="col-sm-4 black-color" onClick={focusElement}>
                                        <input type="checkbox" name="RequierePlantillaCargaManual" onChange={handleChange} checked={formValues?.RequierePlantillaCargaManual ?? true}></input>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-RequierePlantillaCargaManual-validation-message`}></p>
                                    </div>

                                    <label className="col-sm-2 col-form-label text-justify">
                                            No requiere Carta de Incremento
                                        </label>
                                        <div className="col-sm-4 black-color" onClick={focusElement}>
                                            <input type="checkbox" name="NoRequiereCartaIncremento" onChange={handleChange} checked={formValues?.NoRequiereCartaIncremento ?? false}></input>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NoRequiereCartaIncremento-validation-message`}></p>
                                    </div>
                                </>
                            }
                            </div>
                        </>
                        }
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default AgregarEditarColaborador;