import React, { useEffect, useState } from "react";
import { getTiposHorariosLactanciaMaternidadAPI, guardarActualizarHorarioLactanciaAPI } from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { usePropsSelect } from "../../../hooks/useProps/usePropsSelect";
import { initialAllOptionPromises } from "../../../helper/utils";
import { usePropsInputs } from "../../../hooks/useProps/usePropsInput";
import ConvenioHorarioLactancia from "./ConvenioHorarioLactancia";

const KEYS_DROPDOWN = ["IdTipoHorarioLactancia"];
const SECTION_NAME = "SeleccionarHorarioLactancia";
const KEYS = {
  idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  idTipoHorarioLactancia: "IdTipoHorarioLactancia",
  idHorarioLactancia: "IdHorarioLactancia"
};

const SeleccionarHorarioLactancia = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad
  });
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [isLoading, setIsLoading] = useState(false);

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getTiposHorariosLactanciaMaternidadAPI())
    ]
    await initialAllOptionPromises(dropdownsPromises, KEYS_DROPDOWN, setAllDropdowns);
    setIsLoading(false);
  }

  useEffect(() => {
    getDropdowns();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = guardarActualizarHorarioLactanciaAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  }

  const footarMessage = (
    <div className="text-danger">
      <strong>
        <p>
          **Recuerda que para la elección del horario de lactancia, debes haber coordinado previamente con tu jefe directo o Director (a) de sede.
        </p>
      </strong>
    </div>
  );

  return (
    <ConvenioHorarioLactancia
      KEYS={KEYS}
      SECTION_NAME={SECTION_NAME}
      showModal={showModal}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      label="Enviar"
      formValues={formValues}
      setFormValues={setFormValues}
      selectProps={selectProps}
      commonProps={commonProps}
      puedeEditarHorarios={true}
      puedeVerArchivos={false}
      footarMessage={footarMessage}
    />
  );
};

export default SeleccionarHorarioLactancia;
