import React, { useState } from 'react'
import InputText from '../../../components/modalComponents/InputText';
import GenericModal from '../../../components/modal/GenericModal';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';

export const RechazarSolicitud = ({
  toggleModal,
  idSolicitudCambioCuentaSueldo,
  empleado,
  rechazarSolicitud
}) => {
  const SECTION_NAME = 'RechazarSolicitud';
  const KEYS = {
    IdSolicitudCambioCuentaSueldo: "IdSolicitudCambioCuentaSueldo",
    MotivoRechazo: "MotivoRechazo"
  }
  const [formValues, setFormValues] = useState({
    IdSolicitudCambioCuentaSueldo: idSolicitudCambioCuentaSueldo,
    Colaborador: empleado,
  });
  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  return (
    <GenericModal showModal={toggleModal} width={"850px"} titulo="Rechazar cambio de cuenta sueldo">
      <div className="modal-body">
        <InputText
          label="Colaborador"
          name='Colaborador'
          isDisabled={true}
          {...commomProps("Colaborador")}
        />
        <InputTextArea
          label="Motivo de rechazo"
          name='MotivoRechazo'
          isRequired={true}
          {...commomProps("MotivoRechazo")}
        />
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className="button" style={{ alignSelf: "flex-end" }}>
          <button
            className="btn btn-danger generic-button-ficha"
            onClick={() => {rechazarSolicitud(KEYS, formValues, SECTION_NAME)}}>
            Rechazar solicitud
          </button>
        </div>
      </div>
    </GenericModal>
  );
}
