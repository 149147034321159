import React, { useContext, useEffect, useState } from 'react'
import GenericModal from '../modal/GenericModal'
import { usePropsInputs } from '../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../hooks/useProps/usePropsSelect';
import InputText from '../modalComponents/InputText';
import InputSelect from '../modalComponents/InputSelect';
import InputBool from '../modalComponents/InputBool';
import { initialAllOptionPromises, isCurrentDayGreaterThanNumber } from '../../helper/utils';
import { cancelarSolicitudCambioSueldo, crearSolicitudCambioSueldo, getBancosValidosAPI, getSolicitudCambioCuentaSueldoByIdEmpleado, getInfoCambioCuentaSueldoAPI } from '../../consumers/backendApisUrls';
import { useGetOptionsSelect } from '../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { AuthContext } from '../../hooks/context/authContext';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import { responseCode200 } from '../../consumers/httpRequiestsUtils';
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';

export const ModalCambioCuentaSueldo = ({
  showModal,
  title = "Cambio de cuenta sueldo",
  idEmpleado = null,
}) => {
  const SECTION_NAME = "CambioCuentaSueldo";
  const KEYS_INIT = {
    banco: "IdBanco",
    cuenta: "NroCuentaSueldo",
    declaracion: "DJCuentaSueldoAmiNombre",
    conocimiento: "DJConocimientoCartillaCambioCuentaSueldo",
  };
  const { state } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({
    IdEmpleado: idEmpleado || state.user.id_empleado,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [allDropdowns, setAllDropdowns] = useState([]);

  const [url, setUrl] = useState("");

  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(
    allDropdowns,
    formValues,
    setFormValues,
    SECTION_NAME
  );
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useGetRequest();
  const [executePost] = usePostRequest();
  const [submitForm] = useSubmitForm();
  const [canChangeCuentaSueldo, setCanChangeCuentaSueldo] = useState(
    !isCurrentDayGreaterThanNumber(20)
  );

  const initialPromises = () => {
    return [getOptions(getBancosValidosAPI()), getCondicionesMedicas()];
  };

  const getCondicionesMedicas = async function () {
    const response = await executeGet(
      getSolicitudCambioCuentaSueldoByIdEmpleado(idEmpleado || state.user.id_empleado)
    );
    if (responseCode200(response)) {
      if (response?.data?.IdSolicitudCambioCuentaSueldo) {
        setCanChangeCuentaSueldo(false);
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          ...response.data,
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await submitForm(
        Object.values(KEYS_INIT),
        crearSolicitudCambioSueldo(),
        formValues,
        SECTION_NAME,
        successCallback
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await submitForm(
        Object.values(KEYS_INIT),
        cancelarSolicitudCambioSueldo(),
        formValues,
        SECTION_NAME,
        successCallback
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const successCallback = async () => {
    showModal();
  };

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(
        initialPromises,
        Object.values(KEYS_INIT),
        setAllDropdowns
      );
      GetDataForModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const GetDataForModal = async () => {
    const response = await executeGet(
      getInfoCambioCuentaSueldoAPI()
    );

    if (responseCode200(response)) {
      if (response?.data?.UrlPdfFile) {
         setUrl(response?.data?.UrlPdfFile)
      }
    }
  }

  const Link =  () => 
  {
    return (<span><a href={url} target='blank'>ver aquí</a></span>)
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <GenericModal showModal={showModal} width={"850px"} titulo={title}>
      <div className="modal-body">
        {!canChangeCuentaSueldo && (
          <div className="alert alert-warning" role="alert">
            <strong>Atención:</strong> Podrá cambiar su cuenta sueldo entre el 1 y 19 (inclusive) de cada mes.
          </div>
        )}
        <InputSelect
          label="Banco"
          isRequired={true}
          isDisabled={isLoading || !canChangeCuentaSueldo}
          {...commonPropsSelect("IdBanco")}
        />
        <InputText
          label="Cuenta"
          isRequired={true}
          isDisabled={isLoading || !canChangeCuentaSueldo}
          type="number"
          {...commomProps("NroCuentaSueldo")}
        />
        <InputBool
          label="Declaro bajo juramento que la cuenta que se indica está a mi nombre"
          additionalClass="mb-4"
          isRequired={true}
          isDisabled={isLoading || !canChangeCuentaSueldo}
          {...commomProps("DJCuentaSueldoAmiNombre")}
        />
        <InputBool
          label="He tomado conocimiento de la cartilla informativa sobre este procedimiento"
          additionalClass="mb-3"
          isRequired={true}
          isDisabled={isLoading || !canChangeCuentaSueldo}
          additionalLabel={Link}
          {...commomProps("DJConocimientoCartillaCambioCuentaSueldo")}
        />
      </div>
      <div
        className="modal-footer"
        style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className="button" style={{ alignSelf: "flex-end" }}>
          {formValues.IdSolicitudCambioCuentaSueldo ? (
            <button
              className="btn btn-danger generic-button-ficha"
              disabled={isLoading}
              onClick={handleCancel}>
              Cancelar solicitud
            </button>
          ) : (
            <button
              className="btn btn-primary generic-button-ficha"
              disabled={isLoading || !canChangeCuentaSueldo}
              onClick={handleSubmit}>
              Enviar solicitud
            </button>
          )}
        </div>
      </div>
    </GenericModal>
  );
};
