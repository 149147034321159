import React, { useContext, useEffect, useState } from 'react'
import PropTypes from "prop-types"
import { Div, MESES_GESTACION } from './components/Components';
import Gestante from './components/Gestante';
import Lactancia from './components/Lactancia';
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputText from '../../../components/modalComponents/InputText';
import InputDate from '../../../components/modalComponents/InputDate';
import InputSelect from '../../../components/modalComponents/InputSelect';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { AuthContext } from '../../../hooks/context/authContext';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { MATERNIDAD_CONDICION } from '../../../constants/hardCodedConstants';
import { initialAllOptionPromises, toIsoForInput } from '../../../helper/utils';
import { getTipoDeCondicionAPI, registrarCondicionAPI } from '../../../consumers/backendApisUrls';

const KEYS = { idEmpleado: "IdEmpleado", idTipoDeCondicion: "IdTipoDeCondicion", meses: "MesesGestacion", fechaNacimiento: "FechaNacimiento", fechaProbable: "FechaProbableDeParto", fechaUltMenstruacion: "FechaUltimaMenstruacion", nombre: "NombreBebe", file: "SustentoFile", fechaFinCondicion: "FechaFinCondicion" };
const KEYS_DROPDOWN = ["IdTipoDeCondicion"];
const SECTION_NAME = "CrearPopUpGenerico";

const CrearMaternidad = ({ toggleModal, refreshPage, puedeCrearLactancia }) => {
  const { state } = useContext(AuthContext);
  const idEmpleado = state.user.id_empleado;
  
  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getTipoDeCondicionAPI())
    ]
    await initialAllOptionPromises( dropdownsPromises, KEYS_DROPDOWN, handleDropdowns );
    setIsLoading(false);
  }

  const handleDropdowns = (data) => {
    const updatedData = [...data, {key: KEYS.meses, value: MESES_GESTACION}];
    setAllDropdowns(updatedData);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = registrarCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { SustentoFile, IdTipoDeCondicion, ...rest } = formValues
    let selectedStates = {};
    if (formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE){
      const { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion } = rest;
      selectedStates = { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion };
    }
    if (formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA){
      const { FechaNacimiento, NombreBebe } = rest;
      selectedStates = { FechaNacimiento, NombreBebe };
    }
    return {
      IdEmpleado: idEmpleado,
      SustentoFile,
      IdTipoDeCondicion,
      ...selectedStates
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  function canCreate() {
    return formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA
      ? puedeCrearLactancia
      : true;
  }

  useEffect(() => {
    getDropdowns();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm titulo="Nuevo registro de maternidad" width='1100px' showModal={toggleModal} isSubmitting={isSubmitting} saveButtonDisabled={isUploading || isSubmitting || !canCreate()} onSubmit={handleSubmit}>
      <div className="displayFlexWrap">
        <Div><InputDate label="Fec. Reg." name="Fec. Reg." value={toIsoForInput(new Date())} isDisabled /></Div>
        <Div><InputText label="Estado" name="Estado" value="Pendiente de Aprobación" isDisabled /></Div>
        <Div><InputSelect label='Condición' {...selectProps(KEYS.idTipoDeCondicion, [KEYS.fechaProbable, KEYS.fechaUltMenstruacion, KEYS.meses, KEYS.fechaNacimiento, KEYS.nombre])}/></Div>
        {formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE &&
          <Gestante commonProps={commonProps} selectProps={selectProps} keys={KEYS}/>
        }
        {formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA &&
          <Lactancia 
            commonProps={commonProps}
            keys={KEYS} 
            puedeCrearLactancia={puedeCrearLactancia}
          />
        }
        {Object.values(MATERNIDAD_CONDICION).includes(formValues[KEYS.idTipoDeCondicion]) &&
          <Div>
            {formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE &&
              <span className='required font-size-search-label-text'>* Se requiere subir la cartilla de control pre natal</span>
            }
            {formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA &&
              <span className='required font-size-search-label-text'>* Adjuntar el documento de Acta de Nacimiento o copia de DNI, este registro solo se toma para fines de registro de lactancia, no significa el pago de asignación familiar</span>
            }
            <InputUploadFile label="Sustento" name={KEYS.file} uploadFile={formValues[KEYS.file]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.file]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={["pdf", "jpg", "png"]}/>
          </Div>
        }
      </div>
    </ModalForm>
  )
}

CrearMaternidad.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  refreshPage: PropTypes.func
}

export default CrearMaternidad;